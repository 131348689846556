/* Common Imports */

import React from "react";

/* Redux Imports */

import { ObjectId } from "mongoose";
import StateInterface from "../../../redux-magic/state-interface";
import { setHomeLoadingThunk } from "../../../redux-magic/thunks";

/* Component Imports */

import PropertyLoadingCardMini from "../skeletonLoadingCard/PropertyLoadingCardMiniTwo";
import PropertyCard from "./PropertyCardGridMiniTwo/PropertyCardGridMiniTwo";

/* Styled Components */

const PropertyCardGridView = ({
	PropertyId,
	dispatch,
	session,
	profile_context,
	isEditor,
	source,
	id,
	search_log_id,
	bottom_beegru_points,
	boost,
	carousel_width_height,
	// filter_hash,
}: {
	PropertyId: any;
	dispatch: any;
	session: StateInterface["redux_session"];
	profile_context: any;
	isEditor?: boolean | undefined;
	source?: string;
	id?: string;
	search_log_id?: string | ObjectId;
	bottom_beegru_points?: StateInterface["bottom_subscribe_buy_beegru_points"];
	boost?: StateInterface["boost"];
	carousel_width_height?: string;
	// filter_hash?: string;
}) => {
	const [propertyData, setPropertyData] = React.useState<any>({});

	const [saved_by_current_user, setSavedByCurrentUser] = React.useState<boolean>(false);

	const [loading, setLoading] = React.useState(true);

	const [boostPropertySlug, setBoostPropertySlug] = React.useState<string>("");

	const boost_active: boolean = boost?.active || false;

	const boost_property_slug = boost?.property_id || null;

	React.useEffect(() => {
		if (PropertyId) {
			fetch(
				`${process.env.PRODUCTION_API_URL}properties/cards/${PropertyId}
				${carousel_width_height ? `?resize=${carousel_width_height?.toString()}` : ""}
				`,
				{
					method: "POST",
					headers: {
						"Content-Type": "text/plain",
					},
				},
			)
				.then((res) => res.json())
				.then((data) => {
					setPropertyData(data.property_data);
					setLoading(false);
					if (source === "carousel") {
						dispatch(
							setHomeLoadingThunk({
								loading_hot_properties: false,
								// loading_top_business_profiles: true,
								// loading_top_users: true,
								// loading_top_services: true,
							}),
						);
					}
				});
			if (session) {
				fetch(`${process.env.PRODUCTION_API_URL}saved-by-current-user`, {
					method: "POST",
					headers: {
						"Content-Type": "text/plain",
					},
					body: JSON.stringify({
						property_id: PropertyId,
						profile_context: profile_context,
						entity_type: "property",
					}),
				})
					.then((res) => res.json())
					.then((data) => {
						setSavedByCurrentUser(data.payload.saved_by_current_user ? data.payload.saved_by_current_user : false);
					});
			}
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [PropertyId, profile_context, session]);

	React.useEffect(() => {
		if (boost_property_slug === propertyData.slug) {
			setBoostPropertySlug(boost_property_slug as string);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [boost_property_slug]);

	React.useEffect(() => {
		if (boostPropertySlug === propertyData.slug) {
			fetch(`${process.env.PRODUCTION_API_URL}properties/cards/${PropertyId}`, {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
			})
				.then((res) => res.json())
				.then((data) => {
					setPropertyData(data.property_data);
					setLoading(false);
				});
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [boost_active]);

	if (loading) {
		return (
			<PropertyLoadingCardMini
				source={source}
				isEditor={isEditor}
			/>
		);
	}

	return (
		<React.Fragment>
			<PropertyCard
				id={id}
				profile_context={profile_context}
				session={session ? session : null}
				dispatch={dispatch}
				loading={loading}
				_id={propertyData._id}
				slug={propertyData.slug}
				transaction_type={propertyData ? propertyData.transaction_type : ""}
				property_type={propertyData.property_type}
				title={propertyData.title}
				images={propertyData.images}
				location={propertyData.location ? propertyData.location.title : ""}
				price={propertyData.price}
				area={propertyData.area}
				bhk={propertyData.bhk}
				no_of_seats={propertyData.no_of_seats}
				saved_content={saved_by_current_user}
				boosting={propertyData.boost_information}
				created_by={propertyData.created_by}
				source={source}
				published_status={propertyData.status}
				isEditor={isEditor}
				search_log_id={search_log_id as string}
				ad_text={propertyData.ad_text}
			/>
		</React.Fragment>
	);
};

export default PropertyCardGridView;
